const form = () => {
  const checkbox = document.querySelector("#checkbox");
  const checkboxModern = document.querySelector(".checkbox");
  if (checkboxModern) {
    checkboxModern.addEventListener("click", (e) => {
      if (checkbox.checked) {
        checkboxModern.classList.remove("checkbox_checked");
      } else {
        checkboxModern.classList.add("checkbox_checked");
      }
    });
  }
};

export { form };
