
const services = ({service = '', content = '', classService = '', classContent = ''}) => {
    const items = document.querySelectorAll(service);
    const blocks = document.querySelectorAll(content);

    for (let i = 0; i < items.length; i++) {
        items[i].addEventListener('click', () => {
            items.forEach(item => item.classList.remove(classService));
            blocks.forEach(block => block.classList.remove(classContent));
            items[i].classList.add(classService);
            blocks[i].classList.add(classContent);
            blocks[i].scrollIntoView({
                block: "start", 
                behavior: "smooth"
            });
        })
    }
}

export { services };