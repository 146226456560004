const logoAnimate = () => {
    const letters = document.querySelectorAll(".l_letter");

    if (letters) {
      window.addEventListener("load", () => { 
        letters.forEach((letter) => {
            letter.classList.add("anim");
        });
      });
      window.addEventListener("scroll", () => { 
        letters.forEach((letter) => {
            if(window.scrollY > letter.getBoundingClientRect().height*1.5) {
                letter.classList.remove("anim");
            } else {
                letter.classList.add("anim");
            }
          
        });
      });
    }
  };
  
  export { logoAnimate };
  