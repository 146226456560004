const forms = () => {
    const form = document.querySelectorAll('form'),
        inputs = document.querySelectorAll('input'),
        textarea = document.querySelectorAll('textarea');



    const postData = async(url, data) => {
        let res = await fetch(url, {
            method: "POST",
            body: data
        });

        return await res.text();
    };

    const clearInputs = () => {
        inputs.forEach(input => {
            input.value = '';
        })
    }
    const clearTextarea = () => {
        textarea.forEach(item => {
            item.value = '';
        })
    }

    form.forEach(item => {
        item.addEventListener('submit', (e) => {
            e.preventDefault();



            const formData = new FormData(item);
            postData('../server.php', formData)
                .then(res => {
                    console.log ('send message success');
                })
                .catch(() => console.log ('send message failed'))
                .finally(() => {
                    clearInputs();
                    clearTextarea();

                });
        });
    });

}

export {forms};