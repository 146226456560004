import show from '../assets/images/modal/show.svg';
import hide from '../assets/images/modal/hide.svg'

const loggin = () => {



    const login = document.querySelector('.loggin');
    const loginWrapper = document.querySelector('.loggin_wrapper');
    const closeLogin = document.querySelector('.back');
    const btn = document.querySelector('.form_btn');
    const image = document.querySelector('.show_password');
    const password = document.querySelector('#password');
    const email = document.querySelector('#email');
    const inputs = document.querySelectorAll('.form__loggin input');
    
    const passwordWrapper = password.parentNode;
    const emailWrapper = email.parentNode;
    
    let errorPassword;
    let errorEmail;
    let errAut;
    let errEm = true;
    let errPs = true;
    
    const btnActive = () => {
        if(errEm || errPs) {
            btn.disabled = true;
        }  else {
            btn.disabled = false; 
        }
    }


    const open = () => {
        loginWrapper.style.display = 'grid';
        document.documentElement.style.overflow = 'hidden';
        errAut = document.createElement('div')
        errAut.classList.add('err-aut');
        loginWrapper.appendChild(errAut);

        errorPassword = document.createElement('div');
        errorPassword.classList.add('error-check');
        passwordWrapper.insertBefore(errorPassword, password);
    
        errorEmail = document.createElement('div');
        errorEmail.classList.add('error-check');
        emailWrapper.insertBefore(errorEmail, email);
    }

    const close = () => {
        loginWrapper.style.display = '';
        document.documentElement.style.overflow = '';
        errAut.remove();
        errorEmail.remove();
        errorPassword.remove();
        inputs.forEach(input => input.value = '');
        image.src = `${show}`;
        password.type = 'password';
    }

    const showPassword = () => {
        if(password.type === 'password') {
            password.type = 'text';
            image.src = `${hide}`;
        } else {
            password.type = 'password';
            image.src = `${show}`;
        }
    }

    const emailHandler = (e) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!re.test(String(e.target.value).toLocaleLowerCase()) ) {
            errorEmail.innerHTML = 'Incorrect email';
            errEm = true;
            if(!e.target.value){
                 errorEmail.innerHTML =`Email can't be empty`;
                 errEm = true;
            }
        } else {
            errorEmail.innerHTML = '';
            errEm = false;
        }
    }

    const passwordHandler = (e) => {

        if (e.target.value.length < 8) {
            errorPassword.innerHTML = 'Password must be at least 8 symbols ';
            errPs = true;
         if (!e.target.value) {
            errorPassword.innerHTML = `Password can't be empty`;
            errPs = true;
         }
        } else {
            errorPassword.innerHTML = '';
            errPs = false;
        }
    
    }

    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'email':
                emailHandler(e);
                break;
            case 'password':
                passwordHandler(e);
                break;
        }
    }

    const focusHandler = (e) => {   
             switch (e.target.name) {
                case 'email':
                    errorEmail.innerHTML = '';
                    break;
                case 'password':
                    errorPassword.innerHTML = '';
                    break;      
                }
    }



    login.addEventListener('click', open);
    image.addEventListener('click', showPassword);

    password.addEventListener('input',(e) => {
        passwordHandler(e);
        btnActive();
    });
    password.addEventListener('blur',(e) => {
        blurHandler(e);
        btnActive(); 
    });
    password.addEventListener('focus',(e) => {
        focusHandler(e);
    });
    email.addEventListener('input',(e) => {
        emailHandler(e);
        btnActive();
    });
    email.addEventListener('blur',(e) => {
        blurHandler(e);
        btnActive(); 
    });
    email.addEventListener('focus',(e) => {
        focusHandler(e);
    });
   const autorization = () => {
    errAut.style.display = 'grid';
        errAut.innerHTML = `
         <h2>Authorisation Error!</h2>
         <p>check your password or email</p>
        `
   }
   btn.addEventListener('click', (e)=> {
        e.preventDefault(); 
        autorization();     
    });
    closeLogin.addEventListener('click', close);
}

export { loggin }