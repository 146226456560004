import { services } from "./scripts/services.js";
import { form } from "./scripts/form.js";
import { email } from "./scripts/email.js";
import { forms } from "./scripts/sendForm.js";
import { certifications } from "./scripts/certifications.js";
import { letterAnimate } from "./scripts/letter-animate.js";
import { logoAnimate } from "./scripts/logo.js";
import { loggin } from "./scripts/loggin.js";


services(
    {
        service : '.services_item', 
        content : '.services_item__content', 
        classService : 'active',
        classContent : 'active_content'
    }
    );

form();
email();
forms();
certifications();
letterAnimate();
logoAnimate();
loggin();