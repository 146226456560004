const letterAnimate = () => {
  const letters = document.querySelectorAll(".letter");

  const container = document.querySelector(".m_footer__letter");
  if (container) {
    window.addEventListener("scroll", () => {
      const top = container.getBoundingClientRect().top;
      const height = container.getBoundingClientRect().height;

      letters.forEach((letter) => {
        if (window.scrollY / 10 + height > top) {
          letter.classList.add("anim");
        } else {
          letter.classList.remove("anim");
        }
      });
    });
  }
};

export { letterAnimate };
