
const email = () => {
    const canvas = document.getElementById('canvas_email');
    const ctx = canvas.getContext("2d");

    
    function draw() {
        canvas.width = innerWidth /3;
        canvas.height = innerHeight /10;
        let size = (innerWidth/100 + innerHeight /100)*1.8 + 'px';
        let positionY = canvas.height /1.15;
    
        if (window.matchMedia("(max-width: 1024px)").matches) {
            canvas.width = innerWidth;
            canvas.height = innerHeight /10;
            size = (innerWidth/100 + innerHeight /100)*1.8 + 'px';
            positionY = canvas.height /1.15;
    
        } 
        ctx.font = `${size} Suisseintl`;
        ctx.fillText("hey@safe-bytes.com", 0, `${positionY}`);
    }

    draw();
    window.addEventListener('resize', draw);

}

export { email }