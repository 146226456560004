import checkmark from "../assets/images/checkmark.svg";

const certifications = () => {
  const blocks = document.querySelectorAll(".check_certificate");

  console.log();

  blocks.forEach((block) => {
    const check = document.createElement("img");
    check.classList.add("certificate_check");
    check.src = checkmark;
    block.appendChild(check);
  });
};

export { certifications };
